<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Hyundai Page</h1>
    <Hero heading="Power the full potential of your Hyundai electric vehicle" headlineLevel="h2" :subheading="true">
      <template #subheading>
        Conquer the roads ahead and enjoy the many benefits offered by Hyundai and the nation’s largest open DC
        Ultra-Fast charging network.
      </template>
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Hyundai/hero.png" />
          <img src="@/assets/images/Hyundai/hero-mobile.png" alt="Electrify America charging stations at dusk"
        /></picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" id="select-model" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient, reliable, and growing network of over 4,250 electric vehicle (EV) chargers coast-to-coast, along
        major highways and routes, to take EV drivers farther than ever before.
      </p>
      <PartnerDropdown id="hyundai-select" v-model="modelChosen" :options="hyundaiDropdownOptions">
        <template #label>
          <div>
            <label for="hyundai-select">Select your Hyundai electric vehicle model</label>
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="modelChosen === null">
        Hyundai electric vehicle drivers can explore the full potential of electric freedom, leaving gas stations and
        MPG in the rear-view mirror.
      </p>
      <section v-else>
        <p>
          <span v-if="modelChosen.includes('2021')">2021 </span><span v-if="modelChosen.includes('2022')">2022 </span
          ><span v-if="modelChosen.includes('2023')">2023 </span>
          <span v-if="modelChosen.includes('2024')">2024 </span>
          <span v-if="modelChosen.includes('KONA')">Hyundai KONA Electric</span>
          <span v-if="modelChosen.includes('IONIQ')">Hyundai IONIQ</span
          ><span v-if="modelChosen.includes('6') || modelChosen.includes('7')"> 5</span>
          <span v-if="modelChosen.includes('8')"> 6</span> models come with
          <span v-if="!modelChosen.includes('6') && !modelChosen.includes('7') && !modelChosen.includes('8')"
            >250 kilowatt hours (kWh) of complimentary</span
          ><span v-if="modelChosen.includes('6') || modelChosen.includes('7') || modelChosen.includes('8')"
            >24 months of complimentary 30-minute DC fast (CCS) and 60-minute Level 2
          </span>
          charging.
          <template v-if="modelChosen.includes('6 IONIQ 2022')">
            <reference-link :num="1">
              Available on new 2022 IONIQ 5 Electric vehicles purchased from an authorized Hyundai dealer. Complimentary
              30 minute DC fast (CCS) or 60 minute Level 2 charging over twenty-four (24) months begins upon vehicle
              purchase. Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify
              America account, app, acceptance of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: 'hyundai-premium-charging-disclosure-en' }"
                >Promotion Terms and Conditions</router-link
              >, enrollment in Hyundai IONIQ 5 Charging plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. Complimentary charging time does not include any parking or idle fees. Electrify
              America may discontinue or modify the promotion, or your access to the promotion, in its sole discretion.
              After benefits terminate or expire, your account will convert to a basic Electrify America “Pass” account.
              Actual speed of charge may vary. Frequent and consecutive charging can permanently decrease battery
              capacity, which may not be covered by your vehicle’s warranty.
            </reference-link></template
          >
          <template v-else-if="modelChosen.includes('7 IONIQ 2023')">
            <reference-link :num="1">
              Available on new 2023 IONIQ 5 Electric vehicles purchased from an authorized Hyundai dealer (excluding
              Alaska, the Hawaiian island of Kauai, Puerto Rico, and the U.S. Virgin Islands). Complimentary 30 minute
              DC fast (CCS) or 60 minute Level 2 charging over twenty-four (24) months begins upon vehicle purchase.
              Non-transferable. Not available for commercial use, such as ridesharing. Requires Electrify America
              account, app, acceptance of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: 'hyundai-premium-charging-disclosure-en' }"
                >Promotion Terms and Conditions</router-link
              >, enrollment in 2023 IONIQ 5 Charging plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. Complimentary charging time does not include any parking or idle fees. There is a
              60-minute waiting period between eligible charging sessions, and the promotion benefits will not apply to
              a charging session initiated within this period. Electrify America may discontinue or modify the
              promotion, or your access to the promotion, in its sole discretion. After benefits terminate or expire,
              your account will convert to a basic Electrify America “Pass” account. Actual speed of charge may vary.
              Frequent and consecutive charging can permanently decrease battery capacity, which may not be covered by
              your vehicle’s warranty.</reference-link
            ></template
          >
          <template v-else-if="modelChosen.includes('8 IONIQ 2023')">
            <reference-link :num="1">
              Available on new model year 2023 IONIQ 6 vehicles purchased from an authorized U.S.-based Hyundai dealer
              (excluding dealers located in Alaska). 24 months of 30-minute complimentary DC fast charging sessions and
              60-minute Level 2 charging sessions begin upon vehicle purchase. Complimentary charging time does not
              include any idle fees and their associated or applicable taxes assessed at the charging station. You will
              be responsible for paying such fees through your account if you incur them during a charging session.
              There is a 60-minute waiting period between eligible charging sessions, and the promotion benefits will
              not apply to a charging session initiated within this period. Non-transferable. Not available for
              commercial use, such as ride-hailing and ridesharing. Requires Electrify America account, app, acceptance
              of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: '2023-ioniq6-charging-plan-disclosure' }"
                >Promotion Terms and Conditions</router-link
              >, enrollment in 2023 IONIQ 6 Charging Plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. After benefits terminate or expire, your account will convert to a basic Electrify
              America “Pass” account. Actual speed of charge may vary.</reference-link
            ></template
          >
          <template v-else-if="modelChosen.includes('8 IONIQ 2024')">
            <reference-link :num="1">
              Available on new model year 2024 Hyundai IONIQ 6 vehicles purchased from an authorized U.S.- based Hyundai
              dealer (excluding dealers located in Alaska). Twenty-four (24) months of 30-minute complimentary DC fast
              charging sessions and 60-minute Level 2 charging sessions begin upon vehicle purchase. Complimentary
              charging time does not include any idle fees and their associated or applicable taxes assessed at the
              charging station. You will be responsible for paying such fees through your account if you incur them
              during a charging session. There is a 60-minute waiting period between eligible charging sessions, and the
              promotion beneﬁts will not apply to a charging session initiated within this period. Nontransferable. Not
              available for commercial use, such as ride-hailing and ridesharing. Requires Electrify America account,
              app, acceptance of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: '2024-ioniq6-charging-plan-disclosure-en' }"
                >Promotion Terms and Conditions</router-link
              >
              enrollment in 2024 IONIQ 6 Charging Plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. After beneﬁts terminate or expire, your account will convert to a basic Electrify
              America “Pass” account. Your actual charging speed may vary.
            </reference-link>
          </template>
          <template v-else-if="modelChosen.includes('7 IONIQ 2024')">
            <reference-link :num="1">
              Available on new model year 2024 Hyundai IONIQ 5 vehicles purchased from an authorized U.S.- based Hyundai
              dealer (excluding dealers located in Alaska). Twenty-four (24) months of 30-minute complimentary DC fast
              charging sessions and 60-minute Level 2 charging sessions begin upon vehicle purchase. Complimentary
              charging time does not include any idle fees and their associated or applicable taxes assessed at the
              charging station. You will be responsible for paying such fees through your account if you incur them
              during a charging session. There is a 60-minute waiting period between eligible charging sessions, and the
              promotion benefits will not apply to a charging session initiated within this period. Non-transferable.
              Not available for commercial use, such as ride-hailing and ridesharing. Requires Electrify America
              account, app, acceptance of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: '2024-ioniq5-charging-plan-disclosure-en' }"
                >Promotion Terms and Conditions</router-link
              >
              enrollment in 2024 IONIQ 5 Charging Plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. After benefits terminate or expire, your account will convert to a basic Electrify
              America “Pass” account. Your actual charging speed may vary.
            </reference-link>
          </template>

          <template v-else
            ><reference-link :num="1"
              >Available on new MY21, MY22, and MY23 KONA Electric and MY21 and MY22 Ioniq Electric vehicles purchased
              from an authorized U.S.-based (excluding Alaska) Hyundai dealer. 250 kilowatt-hours of DC fast charging
              over thirty-six (36) months begins upon vehicle purchase. Non-transferable. Not available for commercial
              use, such as ridesharing. Requires Electrify America account, app, acceptance of
              <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
              <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
              <router-link class="link" :to="{ name: 'hyundai-premium-charging-disclosure-en' }"
                >Promotion Terms and Conditions</router-link
              >, enrollment in Hyundai Premium Charging plan, and $10 deposit to pre-fund idle fees, which may be
              automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when
              account is closed. Complimentary charging time does not include any parking or idle fees. Electrify
              America may discontinue or modify the promotion, or your access to the promotion, in its sole discretion.
              After benefits terminate or expire, your account will convert to a basic Electrify America “Pass” account.
              Actual speed of charge may vary. Frequent and consecutive charging can permanently decrease battery
              capacity, which may not be covered by your vehicle’s warranty.</reference-link
            ></template
          >
        </p>
        <p>
          <span v-if="modelChosen.includes('2021')">2021 </span><span v-if="modelChosen.includes('2022')">2022 </span
          ><span v-if="modelChosen.includes('2023')">2023 </span>
          <span v-if="modelChosen.includes('2024')">2024 </span>
          <span v-if="modelChosen.includes('KONA')">Hyundai KONA Electric</span>
          <span v-if="modelChosen.includes('IONIQ')">Hyundai IONIQ</span
          ><span v-if="modelChosen.includes('6') || modelChosen.includes('7')"> 5</span
          ><span v-if="modelChosen.includes('8')"> 6</span> drivers can explore the full potential of electric freedom,
          leaving gas stations and MPG in the rear-view mirror. For full program details, click
          <router-link
            v-if="modelChosen.includes('KONA')"
            class="link"
            :to="{ name: 'hyundai-premium-charging-disclosure-en' }"
            >here</router-link
          >
          <router-link
            v-if="modelChosen.includes('7 IONIQ 2023')"
            class="link"
            :to="{ name: '2023-ioniq5-charging-plan-disclosure-en' }"
            >here</router-link
          >
          <router-link
            v-else-if="modelChosen.includes('8 IONIQ 2023')"
            class="link"
            :to="{ name: '2023-ioniq6-charging-plan-disclosure-en' }"
            >here</router-link
          >

          <router-link
            v-else-if="modelChosen.includes('8 IONIQ 2024')"
            class="link"
            :to="{ name: '2024-ioniq6-charging-plan-disclosure-en' }"
            >here</router-link
          >

          <router-link
            v-else-if="modelChosen.includes('7 IONIQ 2024')"
            class="link"
            :to="{ name: '2024-ioniq5-charging-plan-disclosure-en' }"
            >here</router-link
          >

          <router-link
            v-else-if="modelChosen.includes('IONIQ')"
            class="link"
            :to="{ name: 'hyundai-ioniq5-charging-disclosure-en' }"
            >here</router-link
          >.
        </p>
      </section>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go with freedom" headlineLevel="h3">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Hyundai/image2.png" />
          <img
            loading="lazy"
            src="@/assets/images/Hyundai/image2-mobile.png"
            alt="Close up of a Hyundai charger port with an Electrify America charger"
        /></picture>
      </template>
      <p v-if="modelChosen === null">
        Get going with Electrify America’s Ultra-Fast charging network. Select your Hyundai model below to learn about
        available benefits.
      </p>
      <section v-else>
        <p v-if="modelChosen.includes('6') || modelChosen.includes('7') || modelChosen.includes('8')">
          Get going with two years of complimentary 30-minute charging. Just follow these simple steps:
        </p>
        <p v-else>Get going with 250 kilowatt hours (kWh) complimentary charging. Just follow these simple steps:</p>
        <ul>
          <li>
            <strong>Step 1:</strong> Download the Electrify America app,
            <ReferenceLink :num="2"
              >Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application.</ReferenceLink
            >
            create your account, and select “Set Up Public Charging.”
          </li>
          <li>
            <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
            <ul>
              <li>Or search for Premium Offer by vehicle information.</li>
              <li>
                Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
                enrollment code.”
              </li>
            </ul>
          </li>
          <li>
            <strong>Step 3:</strong>
            Input your 8-digit enrollment code, which you will receive from your Hyundai dealer and select
            <strong v-if="!modelChosen.includes('6') && !modelChosen.includes('7') && !modelChosen.includes('8')"
              >Hyundai Premium Charging</strong
            ><strong v-else>
              <span v-if="modelChosen.includes('2023')">2023 </span>
              <span v-if="modelChosen.includes('2024')">2024 </span>
              <span v-if="modelChosen.includes('8')">IONIQ 6 Charging Plan</span>
              <span v-else>IONIQ 5 Charging Plan</span> </strong
            >. Input your vehicle’s 17-digit VIN
            <reference-link :num="3"
              >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
              windshield.</reference-link
            >
            for verification.
          </li>
          <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
          <li>
            <strong>Step 5:</strong> Enter payment info and set up auto-reload.
            <ul>
              <li>
                You will need to enter a payment method
                <reference-link :num="4"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station, and the billed amount will be held in your
                  account to cover costs not covered by this plan, such as idle fees and applicable
                  taxes.</reference-link
                >
                to cover any incurred idle fees
                <reference-link :num="5"
                  >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                  will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                  that others can use the charger. You can be notified when your charging session is complete by text
                  message (your mobile carrier’s messaging, data and other rates may apply) or see it on the charger
                  screen.</reference-link
                >
                or applicable taxes.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call
          <strong>1-833-632-2778</strong>
          to contact Electrify America Customer Assistance, available 24/7.
        </p>
      </section>
      <router-link v-if="modelChosen !== null" class="button" :to="{ name: 'mobile-app-en' }"
        >Download the App</router-link
      >
      <a v-else class="button" href="#select-model">SELECT YOUR MODEL</a>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  components: {
    ReferenceLink,
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'Hyundai Electric Vehicle | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/hyundai/' }],
  },
  data() {
    return {
      hyundaiDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2024 Hyundai IONIQ 5', value: '7 IONIQ 2024' },
        { text: '2024 Hyundai IONIQ 6', value: '8 IONIQ 2024' },
        { text: '2023 Hyundai IONIQ 5', value: '7 IONIQ 2023' },
        { text: '2023 Hyundai IONIQ 6', value: '8 IONIQ 2023' },
        { text: '2023 Hyundai KONA Electric', value: '3 KONA 2023' },
        { text: '2022 Hyundai KONA Electric', value: '2 KONA 2022' },
        { text: '2022 Hyundai IONIQ', value: '5 IONIQ 2022' },
        { text: '2022 Hyundai IONIQ 5', value: '6 IONIQ 2022' },
        { text: '2021 Hyundai KONA Electric', value: '1 KONA 2021' },
        { text: '2021 Hyundai IONIQ', value: '4 IONIQ 2021' },
      ],
      modelChosen: null,
    };
  },
  computed: {
    hyundaiModelName() {
      if (this.modelChosen && this.modelChosen.includes('KONA')) {
        return 'Hyundai KONA Electric';
      }
      if (
        this.modelChosen &&
        (this.modelChosen.includes('6 IONIQ 2022') ||
          this.modelChosen.includes('7 IONIQ 2023') ||
          this.modelChosen.includes('7 IONIQ 2024'))
      ) {
        return 'Hyundai IONIQ 5';
      }
      if (
        this.modelChosen &&
        (this.modelChosen.includes('8 IONIQ 2023') || this.modelChosen.includes('8 IONIQ 2024'))
      ) {
        return 'Hyundai IONIQ 6';
      }
      if (this.modelChosen && this.modelChosen.includes('IONIQ')) {
        return 'Hyundai IONIQ';
      }
      return 'Hyundai electric vehicle';
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .hero .hero__image {
  height: auto;
  img {
    object-position: bottom;
    max-height: none;
  }
}
</style>
